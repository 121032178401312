import {
  CombinedSelectOptions,
  Country, Currency,
  DspAccount, featureToggleKeys, FeatureToggles, IabCategoryParent, OperationsOwner, OptionsLists,
  ResponseFromServer,
  SharedStoreAssetConfig,
  SharedStoreAssetKey, SupplySource, Timezone, TrafficSource
} from '../_models/models';
import {BehaviorSubject, Observable} from 'rxjs';
import _, {sortBy} from 'lodash';
import {Common} from '../_common/common.helper';

export type SharedStoreResponseType = FeatureToggles | OptionsLists | CombinedSelectOptions |
DspAccount | Country | OperationsOwner | Timezone | Currency | TrafficSource | IabCategoryParent;

const timezonesResponseHandler = (res: ResponseFromServer<Timezone[]>, config: SharedStoreAssetConfig<Timezone>) => {
  res.data = sortBy(res.data, ['gmtOffset']);
  return res.data.map((tz: Timezone) => Object.assign({...tz}, {dropdownLabel: Common.prototype.timezoneToDropdownLabel(tz)}));
};


const iabCategoriesResponseHandler = (res: ResponseFromServer<IabCategoryParent[]>, config: SharedStoreAssetConfig<IabCategoryParent>) => {
  res.data = _.orderBy(res.data, ['title']);
  res.data.forEach(parent => {
    parent.children = _.orderBy(parent.children, ['title']);
  });
  return res.data;
};

export const sharedAssetsConfigs: Record<SharedStoreAssetKey, SharedStoreAssetConfig<SharedStoreResponseType>> = {
  featureToggles: {
    key: 'featureToggles',
    requestConfig: {
      apiServiceMethod: 'getFeatureToggles',
      methodArguments: [[...featureToggleKeys]],
      responseHandler: null,
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<FeatureToggles>>(null),
    responseIntegrityTest: (res: FeatureToggles) => Object.keys(res).length === [...featureToggleKeys].length,
  },
  optionsLists: {
    key: 'optionsLists',
    requestConfig: {
      apiServiceMethod: 'getOptionsLists',
      responseHandler: null,
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<OptionsLists>>(null),
    responseIntegrityTest: (res: OptionsLists) => Object.keys(res).length > 0,
  },
  combinedSelectOptions: {
    key: 'combinedSelectOptions',
    requestConfig: {
      apiServiceMethod: 'getCombinedSelectOptions',
      responseHandler: null,
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<ResponseFromServer<CombinedSelectOptions>>>(null),
    responseIntegrityTest: (res: ResponseFromServer<CombinedSelectOptions>) => !!res.data,
  },
  dspAccounts: {
    key: 'dspAccounts',
    requestConfig: {
      apiServiceMethod: 'getDspAccounts',
      methodArguments: [{}],
      responseHandler: null,
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<ResponseFromServer<DspAccount[]>>>(null),
    responseIntegrityTest: (res: ResponseFromServer<DspAccount[]>) => res.data.length > 0,
  },
  countries: {
    key: 'countries',
    requestConfig: {
      apiServiceMethod: 'getCountries',
      responseHandler: null,
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<ResponseFromServer<Country[]>>>(null),
    responseIntegrityTest: (res: ResponseFromServer<Country[]>) => res.data.length > 0,
  },
  opsOwners: {
    key: 'opsOwners',
    requestConfig: {
      apiServiceMethod: 'getOpsOwners',
      responseHandler: null,
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<ResponseFromServer<OperationsOwner[]>>>(null),
    responseIntegrityTest: (res: ResponseFromServer<OperationsOwner[]>) => res.data.length > 0,
  },
  bizdevOwners: {
    key: 'bizdevOwners',
    requestConfig: {
      apiServiceMethod: 'getBizdevOwners',
      responseHandler: null,
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<ResponseFromServer<OperationsOwner[]>>>(null),
    responseIntegrityTest: (res: ResponseFromServer<OperationsOwner[]>) => res.data.length > 0,
  },
  timezones: {
    key: 'timezones',
    requestConfig: {
      apiServiceMethod: 'getTimezonesList',
      responseHandler: timezonesResponseHandler,
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<ResponseFromServer<Timezone[]>>>(null),
    responseIntegrityTest: (res: ResponseFromServer<Timezone[]>) => res.data.length > 0,
  },
  currencies: {
    key: 'currencies',
    requestConfig: {
      apiServiceMethod: 'getCurrenciesList',
      responseHandler: (res: ResponseFromServer<Currency[]>) => res.data,
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<ResponseFromServer<Currency[]>>>(null),
    responseIntegrityTest: (res: ResponseFromServer<Currency[]>) => res.data.length > 0,
  },
  trafficSources: {
    key: 'trafficSources',
    requestConfig: {
      apiServiceMethod: 'getJavaTrafficSources',
      responseHandler: null,
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<ResponseFromServer<TrafficSource[]>>>(null),
    responseIntegrityTest: (res: ResponseFromServer<TrafficSource[]>) => res.data.length > 0,
  },
  trafficSourcesByPublisherId: {
    key: 'trafficSourcesByPublisherId',
    requestConfig: {
      apiServiceMethod: 'getPublisherTrafficSources',
      methodArguments: [],
      responseHandler: (res: TrafficSource[]) => ({data: res} as ResponseFromServer<TrafficSource[]>),
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<ResponseFromServer<TrafficSource[]>>>(null),
    responseIntegrityTest: (res: TrafficSource[]) => res.length > 0,
  },
  ssps: {
    key: 'ssps',
    requestConfig: {
      apiServiceMethod: 'getSspBasic',
      responseHandler: null,
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<ResponseFromServer<SupplySource[]>>>(null),
    responseIntegrityTest: (res: SupplySource[]) => res.length > 0,
  },
  iabCategories: {
    key: 'iabCategories',
    requestConfig: {
      apiServiceMethod: 'getIabCategories',
      responseHandler: iabCategoriesResponseHandler
    },
    isLoading: false,
    response$: null,
    queueSubject: new BehaviorSubject<Observable<ResponseFromServer<IabCategoryParent[]>>>(null),
    responseIntegrityTest: (res: ResponseFromServer<Currency[]>) => res.data.length > 0,
  },
};

